// Generated by Framer (ea5544a)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {L7K72pJ3O: {hover: true, pressed: true}};

const cycleOrder = ["L7K72pJ3O"];

const variantClassNames = {L7K72pJ3O: "framer-v-1p4kj6q"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, title, width, ...props}) => { return {...props, ZZOgj8C_U: title ?? props.ZZOgj8C_U ?? "Return home"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ZZOgj8C_U, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "L7K72pJ3O", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-twM3A", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={{webPageId: "augiA20Il"}}><motion.a {...restProps} className={`${cx("framer-1p4kj6q", className)} framer-1135b97`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"L7K72pJ3O"} ref={ref} style={{backgroundColor: "var(--token-216e1058-a195-41a6-86aa-adc367c0238d, rgb(0, 113, 217))", borderBottomLeftRadius: 4, borderBottomRightRadius: 4, borderTopLeftRadius: 4, borderTopRightRadius: 4, ...style}} variants={{"L7K72pJ3O-hover": {backgroundColor: "var(--token-7a102e0a-b2bc-4335-b9d1-2a9e6d84754a, rgb(7, 9, 141))"}, "L7K72pJ3O-pressed": {backgroundColor: "var(--token-c78e71de-3af0-4dad-af48-8208f0c8210e, rgb(0, 38, 81))"}}} {...addPropertyOverrides({"L7K72pJ3O-hover": {"data-framer-name": undefined}, "L7K72pJ3O-pressed": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--framer-font-size": "16px", "--framer-line-height": "20px", "--framer-text-alignment": "center"}}><motion.span style={{"--font-selector": "SW50ZXItU2VtaUJvbGQ=", "--framer-font-family": "\"Inter-SemiBold\", \"Inter\", sans-serif", "--framer-font-size": "16px", "--framer-font-weight": "600", "--framer-text-color": "var(--extracted-1w3ko1f)"}}>Return home</motion.span></motion.p></React.Fragment>} className={"framer-18hv0wd"} data-framer-name={"Continue"} fonts={["Inter-SemiBold"]} layoutDependency={layoutDependency} layoutId={"I833:14083;3:226"} style={{"--extracted-1w3ko1f": "rgba(255, 255, 255, 1)", "--framer-paragraph-spacing": "0px"}} text={ZZOgj8C_U} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-twM3A [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-twM3A .framer-1135b97 { display: block; }", ".framer-twM3A .framer-1p4kj6q { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 10px 16px 10px 16px; position: relative; text-decoration: none; width: min-content; }", ".framer-twM3A .framer-18hv0wd { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-twM3A .framer-v-1p4kj6q .framer-1p4kj6q { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-twM3A .framer-1p4kj6q { gap: 0px; } .framer-twM3A .framer-1p4kj6q > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-twM3A .framer-1p4kj6q > :first-child { margin-left: 0px; } .framer-twM3A .framer-1p4kj6q > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 131
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"RxBoXK_f4":{"layout":["auto","auto"]},"Z15g_2jLb":{"layout":["auto","auto"]}}}
 * @framerVariables {"ZZOgj8C_U":"title"}
 */
const FramerNleyJCIYZ: React.ComponentType<Props> = withCSS(Component, css, "framer-twM3A") as typeof Component;
export default FramerNleyJCIYZ;

FramerNleyJCIYZ.displayName = "Return home";

FramerNleyJCIYZ.defaultProps = {height: 40, width: 131};

addPropertyControls(FramerNleyJCIYZ, {ZZOgj8C_U: {defaultValue: "Return home", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerNleyJCIYZ, [])